<template>
  <div class="design">
    <div class="components shadow">
      <div class="component" @click="selectSpecs()">
        <div style="
          width: 54px;
          height: 54px;
          background-image: url('specs.png');
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          position: relative">
        </div>
        {{ specs.displayName }}
      </div>
      <div class="component" v-for="(id, index) in components.optionIds" v-bind:key="index" @click="select('Side', index)">
        <div :style="getOptionStyle(id)">
          <div :style="getOverlayStyle()"></div>
        </div>
        {{ translations[id] ?? id }}
      </div>
    </div>
    <div class="inspector shadow">
      <div class=main>
        <h2>{{ translations[template.elevator_type] ?? template.elevator_type }}</h2>
        <div style="font-size: 12px; color: grey;">{{ selectedSizeText }}</div>
        <h4 v-if="navigation.optionIds.length == 0 && variants.optionIds.length == 0">
          Versatile elevator for residential and commercial buildings
        </h4>
        <div  v-if="navigation.optionIds.length == 0 && variants.optionIds.length == 0" class=specs>
          <ol style="padding-left: 18px;">
            <li>Select Elevator Size</li>
            <div class="select">
              <select @change="handleSizeSelect">
                <option v-for="(item, index) in sizes" v-bind:key="index" :value="index">{{ item.displayName }}</option>
              </select>
              <div class="select_arrow">
              </div>
            </div>
            <div class="custom_size">
              <div>Enter a Custom Elevator Size (mm)</div>
              <div class="inputs">
                <input type="number" placeholder="Width" id="width" name="width" min="900" max="2900" step="100" @change="handleWidthChange"/>
                <span>x</span>
                <input type="number" placeholder="Depth" id="depth" name="depth" min="1000" max="6400" step="100" @change="handleDepthChange"/>
                <span>x</span>
                <input type="number" placeholder="Height" id="height" name="height" min="2300" max="3100" step="100" @change="handleHeightChange"/>
              </div>
              <div>Enter Elevator Door Size (mm)</div>
              <div class="inputs">
                <input type="number" placeholder="Width" id="door_width" name="door_width" min="700" max="2500" step="100" @change="handleDoorWidthChange"/>
                <span>x</span>
                <input type="number" placeholder="Height" id="door_height" name="door_height" min="2100" max="2800" step="100" @change="handleDoorHeightChange"/>
              </div>
            </div>
            <li>Select Cabin Entrance</li>
            <div class="entrances" >
              <ul style="list-style-type:none; padding: 0px;">
                <li v-for="(item, index) in entrances" v-bind:key="index">
                  <a href="#" class="cabinEntranceOption" @click="loadData(item.data)">{{ translations[item.id] ?? item.id }}</a>
                </li>
              </ul>
            </div>
            <li>Select Door opening type</li>
            <div class="doorTypes" >
              <ul style="list-style-type:none; padding: 0px;">
                <li v-for="(item, index) in openings" v-bind:key="index">
                  <a href="#" class="doorTypeOption" @click="loadData(item.data)">
                    {{ translations[item.id] ?? item.id }} <img :src="`https://design-liftmart-app.s3.me-central-1.amazonaws.com/images/${item.id}.png`" width="54" height="54">
                  </a>
                </li>
              </ul>
            </div>
          </ol>
        </div>
        <h4 v-if="navigation.optionIds.length > 1">Panel Selection</h4>
        <div v-if="navigation.optionIds.length > 1" class="navigation">
          <div class="route" v-for="(id, index) in navigation.optionIds" v-bind:key="index" @click="select('Navigation', index)">
            <img :src="`https://design-liftmart-app.s3.me-central-1.amazonaws.com/images/${id}_of_${navigation.optionIds.length}.png`" width="64" height="64">
          </div>
        </div>
        <h4 :id="variants.title" v-if="variants.optionIds.length > 0">{{ translations[variants.title] ?? variants.title }}</h4>
        <div v-if="variants.layout != 'LightTempreture'" :style="getVariantsOptionStyle(variants)" class=variants>
          <div :style="getVariantOptionStyle(variants)" class="option" v-for="(id, index) in variants.optionIds" v-bind:key="index" @click="select('Main', index)" :id="id">
            <div class="image-container">
              <img
                v-if="variants.title==='title_panels'"
                :style="getVariantOptionImgStyle(variants)"
                :src="`https://design-liftmart-app.s3.me-central-1.amazonaws.com/images/${id}_of_${variants.optionIds.length}.png`"
                width="166"
                height="90">
              <img
                v-else
                :style="getVariantOptionImgStyle(variants)"
                :src="`https://design-liftmart-app.s3.me-central-1.amazonaws.com/images/${id}.png`"
                width="166"
                height="90">
            </div>
            <div v-if="variants.titleDecoration != 'NoTitle'" class="text-container" :style="getVariantOptionTextStyle(variants)">
              <span v-html="translations[id] ?? id"></span>
              <br/>
              <span v-if="variants.layout == 'Detailed'" style="color: #8D8D8D; font-size: 12px;" v-html="translations['details_'+id] ?? 'details_'+id"></span>
            </div>
          </div>
        </div>
        <div v-else class="temp-container">
          <div class="temp-labels">
            <p>Cool White</p>
            <p>White Light</p>
            <p>Warm White</p>
          </div>
          <div class="temp">
            <input type="range" id="light-temp-range" orient="vertical" v-model="tempRangeValue" min="0" max="2" value="0"  step="1" @input="handleTempRangeChange">
          </div>
        </div>
      </div>
      <div v-if="variants.optionIds.length > 0" class=bottom>
        <a href="#" style="height: 34px; display: flex; align-items: center; color: #2a2c86;" @click="back()">
          <Icon size="24">
            <ChevronLeft48Regular />
          </Icon>
        </a>
        <a v-if="variants.next" href="#" class=liftMartDesignButton @click="next()">{{ translations[variants.next] ?? variants.next }}</a>
      </div>
    </div>
    <div class="render">
      <div class="top">
        <div v-if="lastSelectedExplicitId">
          <img :src="`https://design-liftmart-app.s3.me-central-1.amazonaws.com/images/${lastSelectedExplicitId}.png`" width="64" height="64" style="object-fit: contain;">
          <div style="font-size: 10px; text-align: center;">{{ translations[lastSelectedExplicitId] ?? lastSelectedExplicitId }}</div>
        </div>
        <div v-else></div>
        <div></div>
        <a href="#" @click="toggleDemo()">
          <Icon size="24">
            <Play48Filled color="#2a2c86" />
          </Icon>
        </a>
        <a href="#" @click="resetZoom()">
          <Icon size="24">
            <ArrowClockwise48Filled color="#2a2c86" />
          </Icon>
        </a>
      </div>
      <VueUnity class="unity" :unity="unityContext" height="99.5%"/>
      <div v-if="percentage < 99" class="progress"></div>
      <div class="bottom">
        <a href="#" class=clearSelectionButton @click="showClearModal = true">
          Clear Selection
        </a>
        <div class="zoomControlsGroup">
          <a href="#" @click="zoomIn()">
            <Icon size="24">
              <ZoomOut24Regular />
            </Icon>
          </a>
          <a href="#" @click="resetZoom()">{{ zoom }}%</a>
          <a href="#" @click="zoomOut()">
            <Icon size="24">
              <ZoomIn24Regular />
            </Icon>
          </a>
        </div>
        <a href="#" class="liftMartDesignButton downloadButton" @click="startDesignDownload()">
          <Icon size="24">
            <ArrowDownload48Regular />
          </Icon>
          <span>Download</span>
        </a>
      </div>
    </div>
    <Teleport to="body">
      <LiftMartModal :show="showDownloadModal" @close="showDownloadModal = false">
        <template #header>
          <h3>Download the design</h3>
        </template>
        <template #body>
          <input class="input" type="text" placeholder="Project Name" v-model="projectname">
        </template>
        <template #footer>
          <RoundedLiftMartButton @rounded-lift-mart-button-click="cancelDesignDownload()">
            Cancel
          </RoundedLiftMartButton>
          <RoundedLiftMartButton @rounded-lift-mart-button-click="exportDesign()">
            Download
          </RoundedLiftMartButton>
        </template>
      </LiftMartModal>
    </Teleport>
    <Teleport to="body">
      <LiftMartModal :show="showClearModal" @close="showClearModal = false">
        <template #header>
          <h3>Confirmation needed</h3>
        </template>
        <template #body>
          {{ translations["clear_confirmation"] ?? "clear_confirmation" }}
        </template>
        <template #footer>
          <RoundedLiftMartButton @rounded-lift-mart-button-click="showClearModal = false">
            No
          </RoundedLiftMartButton>
          <RoundedLiftMartButton @rounded-lift-mart-button-click="clearSelection()">
            Yes
          </RoundedLiftMartButton>
        </template>
      </LiftMartModal>
    </Teleport>
  </div>
</template>

<script setup>
import { reactive, ref, onMounted, onUnmounted } from 'vue'
import { Icon } from '@vicons/utils'
import ChevronLeft48Regular from '@vicons/fluent/ChevronLeft48Regular'
import Play48Filled from '@vicons/fluent/Play48Filled'
import ArrowClockwise48Filled from '@vicons/fluent/ArrowClockwise48Filled'
import ZoomIn24Regular from '@vicons/fluent/ZoomIn24Regular'
import ZoomOut24Regular from '@vicons/fluent/ZoomOut24Regular'
import ArrowDownload48Regular from '@vicons/fluent/ArrowDownload48Regular'
import UnityWebgl from 'unity-webgl'
import VueUnity from 'unity-webgl/vue'
import { useRoute  } from 'vue-router'
import LiftMartModal from '../components/LiftMartModal.vue'
import RoundedLiftMartButton from '@/components/RoundedLiftMartButton.vue'

const route = useRoute ()
const domain = "https://cdn.holofair.io/build/2022.3.27f1/graphicstest/2.0.1";

const showDownloadModal = ref(false)
const showClearModal = ref(false)

const specs = {
  "displayName": "Specs",
  "description": "",
  "imageLink": "@/assets/lift_animation_placeholder.png",
  "colorFilter": "none",
  "shape": "default",
  "layout": "auto",
  "segment": "",
  "isSelected": false,
  "index": -1
};

const sizes = [
  {
    displayName: "Select size",
    data: {
      size: "",
      dimensions: "",
      carryingcapacity: ""
    }
  },
  {
    displayName: "900 mm (w) x 1000 mm (d) - 320 kg",
    data: {
      size: "0.9x1.0",
      dimensions: "0.9x2.3x1.0",
      carryingcapacity: "320 kg"
    }
  },
  {
    displayName: "1000 mm (w) x 1200 mm (d) - 450 kg",
    data: {
      size: "1x1.2",
      dimensions: "1x2.3x1.2",
      carryingcapacity: "450 kg"
    }
  },
  {
    displayName: "1100 mm (w) x 1400 mm (d) - 630 kg",
    data: {
      size: "1.1x1.4",
      dimensions: "1.1x2.3x1.4",
      carryingcapacity: "630 kg"
    }
  },
  {
    displayName: "1300 mm (w) x 1500 mm (d) - 800 kg",
    data: {
      size: "1.3x1.5",
      dimensions: "1.3x2.3x1.5",
      carryingcapacity: "800 kg"
    }
  },
  {
    displayName: "1400 mm (w) x 1600 mm (d) - 1000 kg",
    data: {
      size: "1.4x1.6",
      dimensions: "1.4x2.4x1.6",
      carryingcapacity: "1000 kg"
    }
  },
  {
    displayName: "1500 mm (w) x 1900 mm (d) - 1300 kg",
    data: {
      size: "1.5x1.9",
      dimensions: "1.5x2.4x1.9",
      carryingcapacity: "1300 kg"
    }
  },
  {
    displayName: "1600 mm (w) x 2100 mm (d) - 1600 kg",
    data: {
      size: "1.6x2.1",
      dimensions: "1.6x2.4x2.1",
      carryingcapacity: "1600 kg"
    }
  },
  {
    displayName: "1800 mm (w) x 2100 mm (d) - 2000 kg",
    data: {
      size: "1.8x2.1",
      dimensions: "1.8x2.4x2.1",
      carryingcapacity: "2000 kg"
    }
  },
  {
    displayName: "2700 mm (w) x 6380 mm (d) - 4000 kg",
    data: {
      size: "2.7x6.38",
      dimensions: "6.38x2.5x2.7",
      carryingcapacity: "4000 kg"
    }
  },
  {
    displayName: "2900 mm (w) x 6400 mm (d) - 5000 kg",
    data: {
      size: "2.9x6.4",
      dimensions: "6.4x2.5x2.9",
      carryingcapacity: "5000 kg"
    }
  }
]

const entrances = [
  {
    displayName: "Single Entrance",
    id: "single_entrance",
    data: {
      entrance_type: "single_entrance"
    }
  },
  {
    displayName: "Opposite Opening",
    id: "opposite_opening",
    data: {
      entrance_type: "opposite_opening"
    }
  },
  {
    displayName: "90 Entrance",
    id: "90_entrance",
    data: {
      entrance_type: "90_entrance"
    }
  }
]

const openings = [
  {
    displayName: "Two Panel Center Opening",
    id: "2_panel_centre_opening",
    data: {
      front_door: "2_panel_centre_opening"
    }
  },
  {
    displayName: "Two Panel Side Opening",
    id: "2_panel_side_opening",
    data: {
      front_door: "2_panel_side_opening"
    }
  },
  {
    displayName: "Four Panel Center Opening",
    id: "4_panel_centre_opening",
    data: {
      front_door: "4_panel_centre_opening"
    }
  }
]

const unityContext = new UnityWebgl({
  dataUrl: `${domain}/Build/WebGL.data.unityweb`,
  frameworkUrl: `${domain}/Build/WebGL.framework.js.unityweb`,
  loaderUrl: `${domain}/Build/WebGL.loader.js`,
  codeUrl: `${domain}/Build/WebGL.wasm.unityweb`,
  streamingAssetsUrl: `${domain}/StreamingAssets`,
  companyName: "Amrullah Mishelov",
  productName: "LiftMart Design",
  productVersion: "1.2.9"
})

const percentage = ref(0);

const template = ref({
  elevator_type: "passenger_elevator"
});

const projectname = ref("");
const selectedSizeText = ref("");
const selectedSize = ref(1);
const selectedEntrance = ref(1);
const selectedOpening = ref(1);

const lastSelectedExplicitId = ref(null);

unityContext.on('progress', (progress) => {
  percentage.value = parseFloat((progress*100).toFixed(1));
}).on('mounted', (ctx) => {
  console.log('mounted', ctx);
  clearSelection();
})

const components = ref({
  id: "elevator",
  menu: "Side",
  optionIds: []
})

const variants = ref({
  id: "elevator",
  menu: "Menu",
  optionIds: []
})

const navigation = ref({
  id: "elevator",
  menu: "Navigation",
  optionIds: []
})

const tempRangeValue = ref(0)

const translations = reactive({
  "2_panel_centre_opening": "2 Panel Center Opening...."
})

const actualZoomAmount = ref(1);
const zoom = ref("100");

const setComponents = (newComponents) => {
  components.value = newComponents
}

const setVariants = (newVariants) => {
  variants.value = newVariants;
  if(newVariants.showSelection === "Yes") {
    lastSelectedExplicitId.value = newVariants.id;
  } else if(newVariants.showSelection === "Clear") {
    lastSelectedExplicitId.value = null;
  }
}

const setNavigation = (newNavigation) => {
  navigation.value = newNavigation
}

const back = () => {
  const gameObject = 'OptionsViews'
  const methodName = 'Back'
  unityContext.send(gameObject, methodName, "")
}

const selectSpecs = () => {
  const data = {
    id: "elevator",
    menu: "Menu",
    optionIds: []
  };
  setVariants(data);
  data.menu = "Navigation";
  setNavigation(data);
}

const handleTempRangeChange = (event) => {
  select("Main", event.target.value);
}

const select = (menu, index) => {
  const gameObject = 'OptionsViews'
  const methodName = 'Select'
  const data = `${menu},${index}`
  unityContext.send(gameObject, methodName, data)
}

const next = () => {
  const gameObject = 'OptionsViews'
  const methodName = 'Next'
  unityContext.send(gameObject, methodName, "")
}

function clearSelection() {
  showClearModal.value = false;
  unityContext.send("SubjectsController","ClearVariables");
  var design = {
    brand: "liftmart"
  };
  fetch("https://ipinfo.io/json?token=edd414c089c3a5").then(response => {
    if (response.ok) {
      return response.json();
    } else {
      console.log(`Failed to load location. Code ${response.status}. Response: ${response.statusText}`);
      return Promise.reject(`Failed with status: ${response.status}`);
    }
  }).then(location => {
    console.log('Got location info', location);
    Object.assign(design, {
      locationname: `${location.country}_${location.city}`.toLowerCase().replace(/\s+/g, '_')
    });
    Object.assign(design, template.value);
    Object.assign(design, sizes[selectedSize.value].data);
    Object.assign(design, entrances[selectedEntrance.value].data);
    Object.assign(design, openings[selectedOpening.value].data);
    loadData(design);
  }).catch(error => console.error('Error:', error));
}

function zoomIn() {
  actualZoomAmount.value += 0.1;
  unityContext.send("CameraController","SetPercentage", actualZoomAmount.value.toString());
}

function zoomOut() {
  actualZoomAmount.value -= 0.1;
  unityContext.send("CameraController","SetPercentage", actualZoomAmount.value.toString());
}

function toggleDemo() {
  unityContext.send("DemoController","ToggleCameraOrbit");
}

function resetZoom() {
  unityContext.send("CameraController","ResetView");
}

function startDesignDownload() {
  showDownloadModal.value = true;
  unityContext.send("WebGLInputController","DisableCapture");
}

function cancelDesignDownload() {
  showDownloadModal.value = false;
  unityContext.send("WebGLInputController","EnableCapture");
}

function exportDesign() {
  showDownloadModal.value = false;
  unityContext.send("WebGLInputController","EnableCapture");
  unityContext.send("CameraRenders","StartExport");
}

function handleSizeSelect(event) {
  const index = event.target.value;
  selectedSizeText.value = sizes[index].displayName;
  loadData(sizes[index].data);
}

const width = ref(900);
const depth = ref(1000);
const height = ref(2300);

const door_width = ref(700);
const door_height = ref(2100);

function handleWidthChange(event) {
  width.value = event.target.value;
  loadData(formatDimensions());
}

function handleDepthChange(event) {
  depth.value = event.target.value;
  loadData(formatDimensions());
}

function handleHeightChange(event) {
  height.value = event.target.value;
  loadData(formatDimensions());
}

function handleDoorWidthChange(event) {
  door_width.value = event.target.value;
  loadData({
    door_width: door_width.value / 1000
  });
}

function handleDoorHeightChange(event) {
  door_height.value = event.target.value;
  loadData({
    door_height: door_height.value / 1000
  });
}


function formatDimensions() {
  const formatSize = `${(depth.value / 1000).toFixed(1)}x${(width.value / 1000).toFixed(1)}`;
  const formatDimensions = `${(depth.value / 1000).toFixed(1)}x${(height.value / 1000).toFixed(1)}x${(width.value / 1000).toFixed(1)}`;
  selectedSizeText.value = `${width.value} mm (w) x ${depth.value} mm (d) x ${height.value} mm (h)`;
  return {
    size: formatSize,
    dimensions: formatDimensions
  };
}

function loadData(data) {
  console.log("[DesignView] load", JSON.stringify(data));
  unityContext.send("SubjectsController","SetCollectionFromJson", JSON.stringify(data));
}

function handleMessage(event) {
  if(event.state !== "zoom") {
    console.log("[DesignView] Received event from Unity:", event);
    if(event.state === "show") {
      switch(event.data.menu) {
        case "Side":
          setComponents(event.data);
          break;
        case "Main":
          setVariants(event.data);
          break;
        case "Navigation":
          setNavigation(event.data);
          break;
        default:
      }
    } else if(event.state === "export") {
      Object.assign(event.json, {
        projectname: projectname.value
      });
      sendExportRequest(event.json);
    }
  } else {
    actualZoomAmount.value = event.data;
    let zoomAmount = 100 - event.data * 100;
    let parts = zoomAmount.toFixed(2).split(".");
    zoom.value = parts[0].slice(-3);
  }
}

function sendExportRequest(design) {
  fetch(`/api/export?projectName=${projectname.value}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(design),
  }).then(response => {
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  })
  .then(data => {
    if (data.url) {
      window.open(data.url, '_blank');
      console.log('URL opened in a new tab:', data.url);
    } else {
      console.log('No URL found in the response.');
    }
  }).catch(error => {
    console.error('There was a problem with the fetch operation:', error);
  });
}

async function getTranslations() {
  const response = await fetch(`https://node.test.holofair.io/localization/en`);
  if (!response.ok) {
    throw new Error('Network response was not ok ' + response.statusText);
  }
  Object.entries(await response.json()).forEach(([key, value]) => {
    const newKey = key.toLowerCase().replace(/\s+/g, '_');
    translations[newKey] = value;
  });
}

const getOptionStyle = (optionId) => {
  return {
    width: "54px",
    height: "54px",
    backgroundImage: `url(https://design-liftmart-app.s3.me-central-1.amazonaws.com/images/${optionId}.png)`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: "no-repeat",
    position: "relative"
  }
}

const getVariantsOptionStyle = (collection) => {
  if (collection.layout == "Detailed") {
    return {
      gridTemplateColumns: `1fr`
    }
  }
  if (collection.layout == "FourColumns") {
    return {
      gridTemplateColumns: `71px 71px 71px 71px`
    }
  }
  return {
    gridTemplateColumns: `${collection.width}px ${collection.width}px`
  }
}

const getVariantOptionStyle = (collection) => {
  if (collection.layout == "Detailed") {
    return {
      height: `277px`,
      flexDirection: `row`
    }
  }
  if (collection.layout == "FourColumns") {
    return {
      height: `133px`
    }
  }
  return {
    height: `${collection.height}px`
  }
}

const getVariantOptionTextStyle = (collection) => {
  if(collection.titleDecoration == "Container") {
    return {
      height: "50px",
      backgroundColor: "#E5E5E5",
      webkitBoxShadow: "0px 4px 4px 0px rgba(0,0,0,0.25)",
      mozBoxShadow: "0px 4px 4px 0px rgba(0,0,0,0.25)",
      boxShadow: "0px 4px 4px 0px rgba(0,0,0,0.25)"
    }
  }
  if (collection.layout == "Detailed") {
    return {
      width: "135px",
      display: "block",
      textAlign: "left",
      marginTop: "0px",
      alignItems: "baseline"
    }
  }
  return {
    height: "50px",
  }
}

const getVariantOptionImgStyle = (collection) => {
  if(collection.thumbnailShape == "Circle") {
    return {
      borderRadius: "50%"
    }
  }
  return {
    borderRadius: "0%"
  }
}

const getOverlayStyle = () => {
  return {
    width: "54px",
    height: "54px",
    position: "absolute",
    top: "0",
    left: "0",
    backgroundColor: "#2A2B86",
    mixBlendMode: "lighten"
  }
}

onMounted(() => {
  window.addEventListener("message", (event) => handleMessage(event.data), false);
  if (route.query.elevator_type) {
    template.value.elevator_type = route.query.elevator_type;
  }
  getTranslations();
})

onUnmounted(() => {
  window.removeEventListener("message", (event) => handleMessage(event.data), false);
})
</script>

<style scoped>
.design {
  height: 100%;
  display: flex;
}

.components {
  z-index: 2;
  padding: 20px;
  min-width: 100px;
  overflow: auto;
}

.component {
  display: flex;
  width: 64px;
  height: 88px;
  background-color: #f0f0f0;
  padding: 10px;
  margin-bottom: 10px;
  align-items: center;
  flex-flow: column;
}

.component:hover {
  background-color: #fff;
}

.component:active {
  background-color: #ccc;
}

.inspector {
  z-index: 1;
  width: 100%;
  max-width: 392px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
}


.liftMartDesignButton {
  background-color:#2a2c86;
  color:#ffffff;
  border-radius:4px;
  display:inline-block;
  cursor:pointer;
  padding: 4px 22px;
  text-decoration:none;
  line-height: 22px;
}

.liftMartDesignButton:hover {
  background-color:#5557bd;
}

.liftMartDesignButton:active {
  position:relative;
  top:1px;
}

.inspector .main {
  flex: 1;
  padding: 18px;
}

.inspector .bottom {
  display: flex;
  padding: 18px;
  position: sticky;
  bottom: 0;
  background-color: #F1F1F1;
  justify-content: space-between;
}

.inspector .bottom a {
  text-decoration: none;
}

.navigation {
  display: flex;
  justify-content: center;
  justify-items: center;
}

.inspector .variants {
  display: grid;
  gap: 10px;
  justify-content: space-around; 
}

.inspector .variants .option {
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 10px;
  background-color: #f0f0f0;
  display: flex;
  flex-direction: column;
}

.inspector .variants .option:hover {
  background-color: #fff;
}

.inspector .variants .option:active {
  background-color: #ccc;
}

.inspector .variants .option .image-container {
  flex: 1;
  overflow: hidden;
}

.inspector .variants .option .image-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.inspector .variants .option .text-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
}

.render {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 100%;
}

.render .top {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 48px;
  padding: 20px;
  display: grid;
  grid-template-columns: 64px 1fr 48px 48px;
  grid-template-rows: 1fr;
  gap: 0px 12px;
  grid-template-areas:
    ". . . .";
  box-sizing: inherit;
}

.render .top a {
  text-decoration: none;
}

.render .bottom {
  box-sizing: border-box;
  z-index: 1;
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: space-between;
}

.render .unity {
  box-sizing: border-box;
}

.render .progress {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100.8px;
    height: 16.8px;
    background: linear-gradient(#2a2c86 50%,#0000 0),
                linear-gradient(#0000 50%,#2a2c86 0),
                linear-gradient(#2a2c86 50%,#0000 0),
                linear-gradient(#0000 50%,#2a2c86 0),
                linear-gradient(#2a2c86 50%,#0000 0),
                linear-gradient(#0000 50%,#2a2c86 0)
                #b4b6dd;
    background-size: calc(100%/6 + 1px) 200%;
    background-repeat: no-repeat;
    animation: progress-qh65fe 1.2s infinite;
}

@keyframes progress-qh65fe {
   0% {
      background-position: 0% 100%, 20% 0%, 40% 100%, 60% 0%, 80% 100%, 100% 0%;
   }

   16.67% {
      background-position: 0% 0%, 20% 0%, 40% 100%, 60% 0%, 80% 100%, 100% 0%;
   }

   33.33% {
      background-position: 0% 0%, 20% 100%, 40% 100%, 60% 0%, 80% 100%, 100% 0%;
   }

   50% {
      background-position: 0% 0%, 20% 100%, 40% 0%, 60% 0%, 80% 100%, 100% 0%;
   }

   66.67% {
      background-position: 0% 0%, 20% 100%, 40% 0%, 60% 100%, 80% 100%, 100% 0%;
   }

   83.33% {
      background-position: 0% 0%, 20% 100%, 40% 0%, 60% 100%, 80% 0%, 100% 0%;
   }

   100% {
      background-position: 0% 0%, 20% 100%, 40% 0%, 60% 100%, 80% 0%, 100% 100%;
   }
}

.shadow {
  background-color: #f1f1f1;
  -webkit-box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.5);
  -moz-box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.5);
  box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.5);
}

.select {
  position: relative;
  display: inline-block;
  margin-top: 16px;
  margin-bottom: 16px;
  width: 100%;
}

.select select {
  font-family: 'Arial';
  display: inline-block;
  width: 100%;
  cursor: pointer;
  padding: 10px 16px;
  outline: 0;
  border: 1px solid #BEBEBE;
  border-radius: 4px;
  background: #ffffff;
  color: #565659;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.select select::-ms-expand {
  display: none;
}

.select select:hover, .select select:focus {
  color: #565659;
  background: #ffffff;
}

.select select:disabled {
  opacity: 0.5;
  pointer-events: none;
}

.select_arrow {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 0px;
  height: 0px;
  border: solid #BEBEBE;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.select select:hover ~ .select_arrow, .select select:focus ~ .select_arrow {
  border-color: #BEBEBE;
}

.select select:disabled ~ .select_arrow {
  border-top-color: #e6e6e6;
}

.entrances, .doorTypes {
  margin-bottom: 16px;
  width: 100%;
}

.cabinEntranceOption {
  display:inline-block;
  box-sizing: border-box;
  width: 100%;
  background-color:#ffffff;
  border-radius:8px;
  margin-top: 16px;
  border:1px solid #bebebe;
  color:#999;
  cursor:pointer;
  font-family:Arial;
  font-size:15px;
  padding: 12px;
  text-decoration:none;
}

.cabinEntranceOption:hover {
  background-color:#464abf;
  color:#ffffff;
}

.cabinEntranceOption:active {
  position:relative;
  top:1px;
}

.cabinEntranceSelectedOption {
  background-color:#2a2c86;
  color: #ffffff;
}

.doorTypeOption {
  margin-top: 16px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: #999;
  background-color: #F1F1F1;
  text-decoration: none;
  align-items: center; 
}

.doorTypeOption:hover {
  color: #3283FD;
  background-color: #F1F1F1;
  text-decoration: none;
}

.clearSelectionButton {
  display: flex;
  align-content: center;
  background-color: #ECECEC;
  border: 1px solid #BEBEBE;
  border-radius:4px;
  cursor:pointer;
  color:#BEBEBE;
  padding:8px 31px;
  text-decoration:none;
}

.clearSelectionButton:hover {
  background-color: #F1F1F1;
}

.zoomControlsGroup a {
  height: 24px;
  background-color: #ECECEC;
  border: 1px solid #BEBEBE;
  color: #B0B0B0;
  padding: 6px 12px;
  cursor: pointer;
  float: left;
  text-decoration:none;
  align-content: center;
}

.zoomControlsGroup:after {
  content: "";
  clear: both;
  display: table;
}

.zoomControlsGroup a:first-child {
  padding: 6px 6px;
  border-right: none;
  border-radius: 4px 0px 0px 4px;
}

.zoomControlsGroup a:last-child {
  padding: 6px 6px;
  border-left: none;
  border-radius: 0px 4px 4px 0px;
}

.zoomControlsGroup a:hover {
  background-color: #F1F1F1;
}

.downloadButton {
  display: flex;
  align-content: center;
}

.temp-container {
  display: grid; 
  grid-template-columns: 1fr 1fr; 
  grid-template-rows: 1fr; 
  gap: 0px 0px; 
  grid-template-areas: 
    "temp temp-labels"; 
  height: 400px;
}

.temp-labels {
  display: grid; 
  grid-template-columns: 1fr; 
  grid-template-rows: 24px 24px 24px; 
  gap: 0px 0px; 
  grid-template-areas: 
    "."
    "."
    "."; 
  grid-area: temp-labels; 
  align-content: space-between; 
}

.temp { grid-area: temp; }

#light-temp-range {
  -webkit-appearance: none;
  appearance: none;
  width: 24px;
  height: 100%;
  border: 1px solid #B4B4B4;
  background: rgb(119,213,255);
  background: linear-gradient(180deg, rgba(119,213,255,1) 0%, rgba(255,255,255,1) 50%, rgba(255,237,117,1) 100%);
  outline: none;
  border-radius: 8px;
}

/* Thumb: for Chrome, Safari, Edge */
#light-temp-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 36px;
  height: 36px;
  border: 1px solid #B4B4B4;
  border-radius: 32px;
  background: #D9D9D9;
  box-shadow: none;
}

/* Thumb: for Firefox */
#light-temp-range::-moz-range-thumb {
  width: 36px;
  height: 36px;
  border: 1px solid #B4B4B4;
  border-radius: 32px;
  background: #D9D9D9;
  box-shadow: none;
}

.custom_input {
  display: flex;
  align-items: center;
  position: relative;
  max-width: 100%;
}

.input {
  font-size: 18px;
  padding: 5px 10px;
  width: 85%;
  outline: none;
  background: #FFFFFF;
  color: #000000;
  border: 1px solid #C4D1EB;
  border-radius: 5px;
  box-shadow: 3px 3px 2px 0px #E2E2E2;
  transition: .3s ease;
}

.input:focus {
  background: #F2F2F2;
  border: 1px solid #5A7EC7;
  border-radius: 10px;
}

.input::placeholder {
  color: #DDDDDD;
}

.custom_size input {
  width: 83px;
  height: 34px;
}

.custom_size .inputs {
  display: grid;
  grid-template-columns: 2fr 0.5fr 2fr 0.5fr 2fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas:
    ". . . . .";
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 16px;
}

.custom_size .inputs span {
  text-align: center;
}
</style>